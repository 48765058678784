@use '../../styles/variables.scss' as *;

.burgerBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	z-index: 1000;
	right: 0;
	top: 0;
	padding: 0.125em;
	color: $lightBlackColor;
	font-size: 42px;
	background-color: $burgerBtnBackgroundColor;
	box-shadow: 0px 0px 8px 2px $burgerBtnBackgroundColor;
	border: none;
	border-bottom-left-radius: 5px;
	cursor: pointer;
	transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
	&:hover {
		color: $lightBlackColorHover;
		background-color: $burgerBtnBackgroundColorHover;
		box-shadow: 0px 0px 10px 3px $burgerBtnBackgroundColorHover;
	}
}

.navMobile {
	z-index: 1000;
	position: fixed;
	top: 0;
	bottom: 0px;
	left: 0;
	right: 0;
	background-image: $mobileNavBackgroundColor;
	transform: translateX(100%);
	transition: 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
	&Active {
		transform: translateX(0);
	}
	.burgerBtnClose {
		position: absolute;
		padding: 0.15em;
		right: 0;
		top: 0;
		color: $dirtyWhiteColor;
		font-size: 42px;
		background-color: transparent;
		border: none;
		cursor: pointer;
		transition: color 0.3s;
		&:hover {
			color: $navigationItemActiveColor;
		}
	}
	&Items {
		padding: 2.5em 0;
		height: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-around;
		.navItemActive {
			animation: navItemsAnimation 1s backwards;
		}
		.navItem {
			color: $dirtyWhiteColor;
			padding: 0.5m;
			font-size: 24px;
			text-transform: uppercase;
			text-decoration: none;
			word-spacing: 2px;
			letter-spacing: 0.5px;
			transition: color 0.3s;
			&:hover {
				color: $navigationItemActiveColor;
			}
			&First,
			&Second,
			&Third,
			&Fourth,
			&Fifth {
				animation: navItemAnimation 1s backwards cubic-bezier(0.23, 1, 0.32, 1);
			}
			&Second {
				animation-delay: 0.08s;
			}
			&Third {
				animation-delay: 0.16s;
			}
			&Fourth {
				animation-delay: 0.24s;
			}
			&Fifth {
				animation-delay: 0.32s;
			}
			@keyframes navItemAnimation {
				from {
					transform: translateX(250%);
				}
				to {
					transform: translateX(0);
				}
			}
		}
	}
}
.navDesktop {
	z-index: 1000;
	display: none;
	position: sticky;
	top: 0;
	background-image: $desktopNavBackgroundColor;
	&Items {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		.navItem {
			position: relative;
			color: $dirtyWhiteColor;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: bold;
			word-spacing: 2px;
			letter-spacing: 1px;
			transition: color 0.3s;
			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				height: 2px;
				width: 100%;
				transform: scaleX(0);
				background-color: $navigationItemActiveColor;
				transition: transform 0.3s;
			}
			&:hover {
				color: $navigationItemActiveColor;
			}
			&:hover::after {
				transform: scaleX(1);
			}
		}
	}
}

@media (min-width: 768px) {
	.burgerBtn {
		display: none;
	}
	.navMobile {
		display: none;
	}
	.navDesktop {
		display: flex;
		position: sticky;
		top: 0;
		height: $navigationBarHeight;
	}
}
