@use '../../styles/variables.scss' as *;

.gallerySection {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 54px 12px;
	background-color: $contactSectionBackgroundColor;
	.images {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: 175px repeat(2, 150px) 175px auto;
		gap: 0.5em;
		width: 100%;
		max-width: 900px;
		margin-top: 42px;
		.imageFirst {
			grid-row: 1/3;
			grid-column: 1/2;
		}
		.imageSecond {
			grid-row: 3/5;
			grid-column: 1/2;
		}
		.imageThird {
			grid-row: 1/2;
			grid-column: 2/3;
		}
		.imageFourth {
			grid-row: 4/5;
			grid-column: 2/3;
		}
		.imageFifth {
			grid-row: 2/4;
			grid-column: 2/3;
		}
		.imageSixth {
			grid-row: 5/6;
			grid-column: 1/3;
		}
	}
}

@media (min-width: 576px) {
	.gallerySection {
		padding: 72px 0;
		.images {
			margin-top: 54px;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 175px 175px auto;
			gap: 0.75em;
			width: 92%;
			.imageFirst {
				grid-row: 1/3;
				grid-column: 1/2;
			}
			.imageSecond {
				grid-row: 1/2;
				grid-column: 2/3;
			}
			.imageThird {
				grid-row: 1/2;
				grid-column: 3/4;
			}
			.imageFourth {
				grid-row: 2/3;
				grid-column: 2/3;
			}
			.imageFifth {
				grid-row: 2/3;
				grid-column: 3/4;
			}
			.imageSixth {
				grid-row: 3/4;
				grid-column: 1/4;
			}
		}
	}
}

@media (min-width: 768px) {
	.gallerySection {
		padding: 90px 0;
		.images {
			grid-template-rows: 200px 225px auto;
			margin-top: 64px;
		}
	}
}

@media (min-width: 992px) {
	.gallerySection {
		padding: 90px 0;
		.images {
			grid-template-rows: 250px 300px auto;
		}
	}
}
