@use '../../styles/variables.scss' as *;

.quote {
	overflow: hidden;
	position: relative;
	height: 50vh;
	min-height: 300px;
	background-image: $quoteBackgroundColor,
		url('../../../public/images/backgrounds/paper-640.jpg');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	color: $backgroundColor;
	.text {
		position: absolute;
		top: 55%;
		left: 50%;
		width: 80%;
		max-width: 900px;
		transform: translate(-50%, -50%);
		font-family: 'Marcellus', serif;
		.quoteText {
			font-size: 18px;
			text-align: center;
			margin-bottom: 18px;
			letter-spacing: 0.25px;
		}
		.quoteAuthor {
			font-size: 16px;
			text-align-last: right;
			transform: translateX(1vw);
		}
	}
}

@media (min-width: 576px) {
	.quote {
		min-height: 325px;
		background-image: $quoteBackgroundColor,
			url('../../../public/images/backgrounds/paper-1920.jpg');
		.text {
			.quoteText {
				font-size: 20px;
			}
			.quoteAuthor {
				font-size: 18px;
			}
		}
	}
}

@media (min-width: 768px) {
	.quote {
		min-height: 350px;
		.text {
			.quoteText {
				font-size: 24px;
			}
			.quoteAuthor {
				font-size: 20px;
			}
		}
	}
}

@media (min-width: 992px) {
	.quote {
		.text {
			.quoteText {
				font-size: 26px;
			}
			.quoteAuthor {
				font-size: 22px;
			}
		}
	}
}
