$navigationBarHeight: 70px;

$backgroundColor: #eee;
$contactSectionBackgroundColor: #ddd;
$primaryColor: #a5135c;
$primaryColorHover: #870e4b;
$blueColor: #1377a5;
$blueColorHover: #0e668f;
$dirtyWhiteColor: #f5f5f5;
$lightBlackColor: #1b1b1b;
$lightBlackColorHover: #323232;
$grayColor: #676767;
$grayColorHover: #555555;
$footerBackgroundColor: #333;
$fontRed:#db1313;
$fontGreen:#0a8926;

$mobileNavBackgroundColor: linear-gradient(
	326deg,
	rgb(189, 79, 108) 0%,
	rgb(90, 10, 50) 74%
);
$desktopNavBackgroundColor: linear-gradient(
	326deg,
	rgb(115, 13, 40) 0%,
	rgb(76, 6, 41) 74%
);

$quoteBackgroundColor: linear-gradient(
	45deg,
	rgba(189, 79, 108, 0.75),
	rgba(90, 10, 50, 0.75)
);
$aboutBackgroundColor: linear-gradient(
	45deg,
	rgba(189, 79, 108, 0.6),
	rgba(90, 10, 50, 0.6)
);
$navigationItemActiveColor: #18a65f;
$burgerBtnBackgroundColor: #ffffff33;
$burgerBtnBackgroundColorHover: #ffffff4a;

$modalBackgroundColor: #00000099;

$aboutItemBoxShadow: 5px 5px 10px rgba(57, 8, 32, 0.1);

$catalogItemBackground: radial-gradient(
	circle,
	rgb(245, 240, 242) 25%,
	rgba(135, 125, 130, 0.6) 100%
);
