@use '../../styles/variables.scss' as *;

.aboutItem {
	width: 92%;
	box-shadow: $aboutItemBoxShadow;
	background-color: white;
	overflow: hidden;
	.image {
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			transition: transform 0.3s;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
	.info {
		padding: 32px;
		.heading {
			margin-bottom: 24px;
			font-size: 24px;
		}
		.text {
			line-height: 22px;
			text-align: justify;
			font-size: 15px;
			color: $grayColorHover;
		}
	}
	&Right{
		.image {
			order: 2;
		}
		.info {
			order: 1;
		}
	
	}
}

@media (min-width: 576px) {
	.aboutItem {
		width: 75%;
		.info {
			.heading {
				font-size: 26px;
			}
			.text {
				line-height: 24px;
				font-size: 16px;
				margin-bottom: 12px;
			}
		}
	}
}

@media (min-width: 768px) {
	.aboutItem {
		width: 92%;
		max-width: 1200px;
		display: flex;
		.image {
			overflow: hidden;
			width: 40%;
			height: auto;
			img {
				object-fit: cover;
				&:hover {
					transform: scale(1.1);
				}
			}
		}
		.info {
			z-index: 5;
			width: 60%;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			.heading {
				margin-bottom: 32px;
				font-size: 34px;
			}
		}
	}
}
