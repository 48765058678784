@use '../../styles/variables.scss' as *;

.modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.text,
	.subText {
		margin: 0;
		text-align: center;
		font-family: 'Lato', sans-serif;
	}
	.text {
		margin-bottom: 12px;
		font-size: 30px;
		color: $primaryColor;
		font-weight: 600;
		letter-spacing: 0.5px;
		word-spacing: 1px;
	}
	.subText {
		margin-bottom: 16px;
		font-size: 16px;
	}
	.buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		button {
			padding: 8px 40px;
			font-size: 18px;
			margin: 0.25em;
			border: none;
			border-radius: 8px;
			color: $dirtyWhiteColor;
			text-transform: uppercase;
			font-family: 'Lato', sans-serif;
			cursor: pointer;
			transition: background-color 0.3s;
		}
		.confirmBtn {
			background-color: $primaryColor;
			&:hover {
				background-color: $primaryColorHover;
			}
		}
		.cancelBtn {
			background-color: $grayColor;
			&:hover {
				background-color: $grayColorHover;
			}
		}
	}
}
