@use './variables.scss' as *;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Lato', sans-serif;
	background-color: $backgroundColor;
}

.active-scroll-spy {
	color: $navigationItemActiveColor !important;
	transition: color 0.3s;
}

@media (min-width: 576px) {
	html {
		scroll-padding-top: $navigationBarHeight;
	}
}
